import { graphql } from "gatsby"
import React from 'react'
import Layout from "../components/layout"
import ImageGallery from "../wal-common/src/components/Image/ImageGallery/ImageGallery"

const MediaTmp = ({
                    data: {
                      markdownRemark: {
                        frontmatter: { fotos },
                      },
                    },
                  }) => (
  <Layout>
    <ImageGallery fotos={fotos} />
  </Layout>
)

export default MediaTmp

export const pageQuery = graphql`
  query Fotos($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        fotos {
            foto
            copyright
        } 
      }
    }
  }
`

