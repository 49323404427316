import React from 'react'
import ImagePreview from '../ImagePreview/ImagePreview'

const ImageGallery = ({ fotos }) => {
  return (
    <div className="columns is-multiline">
      {fotos.map(({foto, copyright}) => (
        <div key={foto} className="column is-one-third">
          <ImagePreview file={foto} thumb copyright={copyright} />
        </div>
      ))}
    </div>
  )
}

export default ImageGallery
